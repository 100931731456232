import Table from "./Table";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

import Notification from './Notification.js';
import Loader from "./Loader";

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class Inventory {

    /**
     * @returns {jQuery}
     * @param $list
     */
    createAnalyseListTable($list) {
        const TableObject = new Table();

        let inventory = $list.attr('data-inventory');

        return TableObject.createTable($list, {
            ajax: Routing.generate('inventory_analyse_list', {
                'inventory': inventory
            }),
            dom: 'lBip<"table-responsive"rt>p',
            'columnDefs': [
                {'name': 'sku', 'targets': 0},
                {'name': 'warehouse', 'targets': 2},
            ],
            createdRow: function (row, data, dataIndex, cells) {
                let scanned = parseInt(data[5]);
                let scannedNotClosed = parseInt(data[6]);
                let iaiCount = parseInt(data[4]);
                let problemsCount = parseInt(data[10]);

                if (problemsCount === 0 && scanned === iaiCount) {
                    $(cells[0]).addClass('table-success');
                    $(cells[4]).addClass('table-success');
                    $(cells[5]).addClass('table-success');
                    $(cells[10]).addClass('table-success');
                } else if (problemsCount === 0 && scannedNotClosed === scanned && iaiCount === 0) {
                    $(cells[0]).addClass('table-success');
                    $(cells[5]).addClass('table-success');
                    $(cells[6]).addClass('table-success');
                    $(cells[10]).addClass('table-success');
                } else if (problemsCount === 0 && scannedNotClosed + iaiCount === scanned) {
                    $(cells[0]).addClass('table-success');
                    $(cells[4]).addClass('table-success');
                    $(cells[5]).addClass('table-success');
                    $(cells[6]).addClass('table-success');
                    $(cells[10]).addClass('table-success');
                } else {
                    $(cells[0]).addClass('table-danger');
                    $(cells[4]).addClass('table-danger');
                    $(cells[5]).addClass('table-danger');
                    $(cells[6]).addClass('table-danger');
                    if (problemsCount > 0){
                        $(cells[10]).addClass('table-danger');
                    } else {
                        $(cells[10]).addClass('table-success');
                    }
                }

                $(row).addClass('sku-row');
            },
            columns: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                // {'className': 'type', 'targets': 8},
                // {'className': 'url', 'targets': 9},
                // {'className': 'reason', 'targets': 10},
            ],
        });
    }

    /**
     * @returns {*}
     * @param form
     */
    getShelfByShelfNumber(form) {
        let LoaderObject = new Loader();

        let inventorySection = $('.inventory');
        let inventory = inventorySection.data('inventory');
        let inventoryCart = inventorySection.data('inventory-cart');
        let loaderSpinner = LoaderObject.createLoader(inventorySection);

        if ($(form).find('[data-search-shelf-number]').val() === inventorySection.data('inventory-cart-short-code')) {
            window.location.href = Routing.generate('inventory_cart_list', {
                'inventory': inventory,
                'cart_close_sound': 1
            });
        }

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('inventory_cart_shelf_list', {
                'inventory': inventory,
                'inventoryCart': inventoryCart
            }),
            contentType: false,
            processData: false,
            data: new FormData(form),
            success: function (response) {
                LoaderObject.removeLoader(loaderSpinner);

                if (typeof response.shelf !== 'undefined') {
                    window.location.href = Routing.generate('inventory_process_shelf', {
                        'inventory': inventory,
                        'inventoryCart': inventoryCart,
                        'shelf': response.shelf,
                        'shelf_open_sound': 1
                    })
                } else {
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(response.error);
                }
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                // if (xhr.responseJSON.status === 2) {
                //     let $inventoryScanForm = $('.inventory .form.search-form');
                //     let $finishScanButton = $inventoryScanForm.find('.finish-scan');
                //
                //     $finishScanButton.prop('disabled', true);
                // }

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     * @returns {*}
     * @param form
     */
    getCartByCartId(form) {
        let LoaderObject = new Loader();

        let inventorySection = $('.inventory');
        let inventoryId = inventorySection.data('inventory');
        let loaderSpinner = LoaderObject.createLoader(inventorySection);

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('inventory_cart_list', {
                'inventory': inventoryId
            }),
            contentType: false,
            processData: false,
            data: new FormData(form),
            success: function (response) {
                LoaderObject.removeLoader(loaderSpinner);

                if (response.cart !== 'undefined') {
                    window.location.href = Routing.generate('inventory_cart_shelf_list', {
                        'inventory': inventoryId,
                        'inventoryCart': response.cart,
                        'cart_open_sound': 1
                    })
                } else {
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                }
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                $(form).find('[data-search-cart-id]').select();
                // if (xhr.responseJSON.status === 2) {
                //     let $inventoryScanForm = $('.inventory .form.search-form');
                //     let $finishScanButton = $inventoryScanForm.find('.finish-scan');
                //
                //     $finishScanButton.prop('disabled', true);
                // }

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     * @param href
     * @param $table
     * @param $icon
     */
    removeShelfItemFromScannedList(href, $table, $icon) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.inventory-scanned-table-wrapper'));

        $.when($.ajax({
            type: 'GET',
            url: href,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                $icon.closest('tr').remove();

                let rows = $table.find('tr');
                let inventoryScannedTableBody = $table.find('tbody');
                let noResultsRow = inventoryScannedTableBody.find('.no-results');

                let $counterBlock = $('.product-count');
                this.removeCounter($counterBlock.text());

                if (rows.length <= 2) {
                    noResultsRow.show(0);

                    let $finishScan = $('.finish-scan');
                    $finishScan.prop('disabled', true);
                }

                let $problems = $table.find('.table-danger');

                if ($problems.length <= 0) {
                    let $finishScan = $('.finish-scan');
                    $finishScan.prop('disabled', false);
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    addCounter(count) {
        let $counterBlock = $('.product-count');
        $counterBlock.text(parseInt(count) + 1);
    }

    removeCounter(count) {
        let $counterBlock = $('.product-count');
        $counterBlock.text(parseInt(count) - 1);
    }

    /**
     * @param href
     * @param $table
     * @param $icon
     */
    removeShelvesFromList(href, $table, $icon) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.inventory-shelves-table-wrapper'));

        $.when($.ajax({
            type: 'GET',
            url: href,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                $icon.closest('tr').remove();

                let $counterBlock = $('.product-count');
                this.removeCounter($counterBlock.text());
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }
    printBarcode(printButton) {
        let LoaderObject = new Loader();

        let itemId = printButton.data('item-id');

        let inventorySection = $('.card.inventory');
        let inventoryId = inventorySection.data('inventory');

        let skuRow = printButton.closest('tr');
        let loaderSpinner = LoaderObject.createLoader(skuRow);
         
        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('inventory_print_barcode', {
                'inventory': inventoryId,
                'inventoryShelfItem': itemId
            }),
            contentType: false,
            processData: false,
            success: function (response) {
                LoaderObject.removeLoader(loaderSpinner);
                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);
                location.reload();
                window.open(
                    Routing.generate('product_barcode', {
                        'product': response.productId
                    }),
                    '_blank'
                  );
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    addRow(response) {
        let inventoryScannedTableBody = $('.inventory-scanned-table tbody');
        let problems = '<ul class="list-unstyled">';
        let noResultsRow = inventoryScannedTableBody.find('.no-results');

        noResultsRow.hide(0);

        let rowClass = 'table-success';

        if (response.problems.length > 0) {
            rowClass = 'table-danger';

            for (let i in response.problems) {
                problems += '<li>' + response.problems[i] + '</li>';
            }
        } else {
            problems += '------';
        }

        problems += '</ul>';

        if (response.sku === null) {
            response.sku = '';
            response.skuShortName = '';
        }
        let html = '<tr class="' + rowClass + '">' +
            '<td><a target="_blank" href="' + Routing.generate('product_info', {
                product: response.productID
            }) + '"> ' + response.serialNumber + '</a></td>' +
            '<td>' + response.sku + '</td>' +
            '<td>' + response.skuShortName + '</td>' +
            '<td>' + response.productType + '</td>' +
            '<td>' + response.location + '</td>' +
            '<td>' + problems + '</td>' +
            '<td><a href="' + Routing.generate('inventory_item_remove', {
                inventoryShelfItem: response.id
            }) + '" class="rnew-action delete" ><i class="fas fa-trash"></i></a></td>' +
            '</tr>';

        inventoryScannedTableBody.append(html);

        let $counterBlock = $('.product-count');
        this.addCounter($counterBlock.text());
    }

    addDocumentRow($button) {
        let $documentSectionType = $button.attr('data-type');
        let $type = $button.closest('td.type');
        let $url = $type.siblings('td.url');
        let $reason = $type.siblings('td.reason');

        let $documentType = $type.find('.document-type:last');
        let $documentUrl = $url.find('.document-url:last');
        let $documentReason = $reason.find('.document-reason:last');
        let dataReasonSku = $documentReason.attr('data-sku');
        let $documentReasonRow = $reason.find('.document-reason');

        let $documentTypeCloned = $documentType.clone();
        let $documentUrlCloned = $documentUrl.clone();
        let $documentReasonCloned = $documentReason.clone();

        $documentReasonCloned.find('.reason').attr('name', $documentSectionType + '[' + dataReasonSku + '][reason][' + $documentReasonRow.length + ']')

        $type.find('.document-content').append($documentTypeCloned);
        $url.find('.document-content').append($documentUrlCloned);
        $reason.find('.document-content').append($documentReasonCloned);

        let $removeButton = '<i class="remove-document-row delete fas fa-minus-circle"></i>'

        if ($type.find('.controls-section .remove-document-row').length === 0 &&
            $url.find('.controls-section .remove-document-row').length === 0) {
            $type.find('.controls-section').append($removeButton);
            $url.find('.controls-section').append($removeButton);
        }
    }

    removeDocumentRow($button) {
        let $type = $button.closest('td.type');
        let $url = $type.siblings('td.url');
        let $reason = $type.siblings('td.reason');

        let $documentType = $type.find('.document-type:last');
        let $documentUrl = $url.find('.document-url:last');
        let $documentReason = $reason.find('.document-reason:last');

        $documentType.remove();
        $documentUrl.remove();
        $documentReason.remove();

        $documentType = $type.find('.document-type');
        $documentUrl = $url.find('.document-url');
        $documentReason = $reason.find('.document-reason');

        if ($documentType.length <= 1 && $documentUrl.length <= 1 && $documentReason.length <= 1) {
            $type.find('.remove-document-row').remove();
            $url.find('.remove-document-row').remove();
        }
    }

    generateChart($canvas) {
        let ctx = $canvas[0].getContext('2d');

        let labels = JSON.parse($canvas.attr('data-label'));
        let data = JSON.parse($canvas.attr('data-value'));
        let title = $canvas.attr('data-title');

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: title,
                    data: data,
                }]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true
                    },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label) {
                                if (Math.floor(label) === label) {
                                    return label;
                                }

                            },
                        }
                    }],
                }
            }
        });
    }

    handleEvent() {
        let $inventoryScanForm = $('.inventory .form.search-form');
        let $inventoryScanCartForm = $('.inventory .form.search-cart-form');

        let inventoryItemAddSound = $('.inventory-item-add-sound');
        let inventoryItemAddSoundFail = $('.inventory-item-add-sound-fail');

        let inventoryCartOpenSound = $('.inventory-open-cart');
        let inventoryCartCloseSound = $('.inventory-close-cart');
        let inventoryShelfOpenSound = $('.inventory-open-shelf');
        let inventoryShelfCloseErrorSound = $('.inventory-close-shelf-error');
        let inventoryShelfCloseSuccessSound = $('.inventory-close-shelf-success');
 
        let queryString = window.location.search;
        let urlGetParams = new URLSearchParams(queryString);

        if (urlGetParams.has('cart_open_sound')) {
            inventoryCartOpenSound[0].play();
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (urlGetParams.has('cart_close_sound')) {
            inventoryCartCloseSound[0].play();
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (urlGetParams.has('shelf_open_sound')) {
            inventoryShelfOpenSound[0].play();
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (urlGetParams.has('close_sound_error')) {
            inventoryShelfCloseErrorSound[0].play();
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (urlGetParams.has('close_sound_success')) {
            inventoryShelfCloseSuccessSound[0].play();
            window.history.replaceState({}, document.title, window.location.pathname);
        }
        

        let $report = $('.report-chart');

        if ($report.length > 0) {
            this.generateChart($report);
        }

        if ($inventoryScanCartForm.length > 0) {
            let $scanCartIdInput = $inventoryScanForm.find('[data-search-cart-id]');

            $scanCartIdInput.focus();

            $scanCartIdInput.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    $inventoryScanCartForm.trigger('submit');
                }
            });

            $inventoryScanCartForm.on('submit', (event) => {
                event.preventDefault();

                this.getCartByCartId(event.target);
            });
        }

        if ($inventoryScanForm.length > 0) {
            let $scanShelfNumberInput = $inventoryScanForm.find('[data-search-shelf-number]');

            $scanShelfNumberInput.focus();

            $scanShelfNumberInput.on('keyup', (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    $inventoryScanForm.trigger('submit');
                }
            });

            $inventoryScanForm.on('submit', (event) => {
                event.preventDefault();

                this.getShelfByShelfNumber(event.target);
            });
        }

        let $inventoryProcessForm = $('.inventory .form.process-form');

        if ($inventoryProcessForm.length > 0) {
            let $inventoryFormSku = $inventoryProcessForm.find('.sku');
            let $inventoryFormSerialNumber = $inventoryProcessForm.find('.serial-number');

            $(window).keydown((event) => {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    return false;
                }
            });

            $inventoryFormSerialNumber.focus();

            $inventoryFormSerialNumber.on('keyup', (event) => {
                event.preventDefault();
                event.stopPropagation();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    $inventoryFormSku.focus();
                }
            });

            $inventoryFormSku.on('keyup', (event) => {
                event.preventDefault();
                event.stopPropagation();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    let LoaderObject = new Loader();
                    let loaderSpinner = LoaderObject.createLoader($('.inventory.process-form'));
                    let shelfShortCode = $('.inventory.process-form').data('shelf-short-code');
                    let shelf = +$('.inventory.process-form').data('shelf');
                    let inventoryCart = +$('.inventory.process-form').data('inventory-cart');

                    let data = {
                        'sku': $inventoryFormSku.val(),
                        'serialNumber': $inventoryFormSerialNumber.val()
                    };
                    // if (data.sku === data.serialNumber && data.sku === shelfShortCode && data.serialNumber === shelfShortCode) {
                    //     window.location.href = Routing.generate('inventory_cart_shelf_list', {
                    //         'inventory': inventory,
                    //         'inventoryCart': inventoryCart
                    //     });
                    // }
                    $.when($.ajax({
                        type: 'POST',
                        url: Routing.generate('inventory_validate', {
                            'shelf': shelf,
                            'inventoryCart': inventoryCart
                        }),
                        data: data,
                        success: (response) => {
                            LoaderObject.removeLoader(loaderSpinner);

                            $(event.currentTarget).val('');
                            $inventoryFormSerialNumber.val('').focus();

                            let $finishScan = $('.finish-scan');
                            let $processForm = $('.process-form');

                            if (response.status === 5) {
                                if (!$finishScan.is(':disabled')) {
                                    $processForm.submit();
                                    return false;
                                }
                            }

                            this.addRow(response);

                            if (response.problems.length <= 0) {
                                inventoryItemAddSound[0].play();
                                // $finishScan.prop('disabled', false);
                            } else {
                                inventoryItemAddSoundFail[0].play();
                                // $finishScan.prop('disabled', true);
                            }
                        },
                        error: (xhr, status, error) => {
                            LoaderObject.removeLoader(loaderSpinner);

                            $(event.currentTarget).val('');
                            $inventoryFormSerialNumber.val('').focus();

                            const NotificationObject = new Notification();
                            NotificationObject.setErrorAlert(xhr.responseJSON.error);

                            inventoryItemAddSoundFail[0].play();
                        }
                    }))
                }
            });

            let $inventoryShelfItemsList = $('.inventory-scanned-table');

            $inventoryShelfItemsList.off('click').on('click', '.delete', (event) => {
                event.preventDefault();

                let href = $(event.currentTarget).attr('href');

                this.removeShelfItemFromScannedList(href, $inventoryShelfItemsList, $(event.currentTarget));
            });
        }

        let $inventoryShelvesList = $('.inventory-scanned-shelves-table');

        $inventoryShelvesList.off('click').on('click', '.delete', (event) => {
            event.preventDefault();

            let href = $(event.currentTarget).attr('href');

            this.removeShelvesFromList(href, $inventoryShelvesList, $(event.currentTarget));
        });

        $(document).on('click', '.print-barcode', (event) => {
            event.preventDefault();
            this.printBarcode($(event.currentTarget));
            
        });

        $(document).on('click', '.add-document-row', (event) => {
            event.preventDefault();
            this.addDocumentRow($(event.currentTarget));
        });

        $(document).on('click', '.remove-document-row', (event) => {
            event.preventDefault();
            this.removeDocumentRow($(event.currentTarget));
        });

        let $analyseRowsList = $('.analyse-rows-list');

        if ($analyseRowsList.length > 0) {
            this.createAnalyseListTable($analyseRowsList);
        }
    }

    init() {
        this.handleEvent();
    }
}